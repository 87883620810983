import React, { useState } from 'react';
import { showNotifyToast } from '../../utils/notifyToast';
import { approveCustomer, pendingCustomer, selectCustomer, deselectCustomer, isRelatedPartyDebtor, isNotRelatedPartyDebtor, isGovernmentDebtor, isNotGovernmentDebtor, isMajorCorporationDebtor, isNotMajorCorporationDebtor, ignoreCustomerDebtorThreshold, unignoreCustomerDebtorThreshold, setNoaSent, unsetNoaSent } from '../../api/admin_customer';
import AdminCustomerInfoTableBody from './AdminCustomerInfoTableBody';
const AdminCustomerInfoTable = ({ apiToken, borrowerId, customer, customerPhones, customerAddresses, effectiveConcentrationLimit, enableActions, debtorBalance, checkIcon, isManualLedger, debtorLimit, concentrationLimit }) => {
    const [customerData, setCustomerData] = useState(customer);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const errorMessage = 'Something went wrong. Please try again.';
    const updateCustomerData = (updatedCustomer) => {
        const updatedData = {
            ...customerData,
            earlypayStatus: updatedCustomer.earlypayStatus,
            isSelected: updatedCustomer.isSelected,
            debtorThresholdStatus: updatedCustomer.debtorThresholdStatus
        };
        setCustomerData(updatedData);
    };
    const approveCustomerHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await approveCustomer(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const pendingCustomerHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await pendingCustomer(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const selectCustomerHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await selectCustomer(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const deselectCustomerHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await deselectCustomer(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isRelatedPartyDebtorHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await isRelatedPartyDebtor(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isNotRelatedPartyDebtorHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await isNotRelatedPartyDebtor(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isGovernmentDebtorHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await isGovernmentDebtor(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isNotGovernmentDebtorHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await isNotGovernmentDebtor(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isMajorCorporationDebtorHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await isMajorCorporationDebtor(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isNotMajorCorporationDebtorHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await isNotMajorCorporationDebtor(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const ignoreCustomerDebtorThresholdHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await ignoreCustomerDebtorThreshold(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const unignoreCustomerDebtorThresholdHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await unignoreCustomerDebtorThreshold(apiToken, borrowerId, customerData.id);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const setCustomerNoaSentHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await setNoaSent(apiToken, borrowerId, customerId);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const unsetCustomerNoaSentHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await unsetNoaSent(apiToken, borrowerId, customerId);
            updateCustomerData(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    return (React.createElement("table", { className: 'custom-table -fixed', "data-testid": 'admin-customer-info-table' },
        React.createElement(AdminCustomerInfoTableBody, { ...{
                customerData,
                customerPhones,
                customerAddresses,
                selectCustomerHandler,
                deselectCustomerHandler,
                approveCustomerHandler,
                pendingCustomerHandler,
                isRelatedPartyDebtorHandler,
                isNotRelatedPartyDebtorHandler,
                isGovernmentDebtorHandler,
                isNotGovernmentDebtorHandler,
                isMajorCorporationDebtorHandler,
                isNotMajorCorporationDebtorHandler,
                ignoreCustomerDebtorThresholdHandler,
                unignoreCustomerDebtorThresholdHandler,
                isRequestPending,
                effectiveConcentrationLimit,
                enableActions,
                checkIcon,
                debtorBalance,
                isManualLedger,
                debtorLimit,
                concentrationLimit,
                setCustomerNoaSentHandler,
                unsetCustomerNoaSentHandler
            } })));
};
export default AdminCustomerInfoTable;
