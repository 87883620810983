import React, { useLayoutEffect, useRef, useState } from 'react';
import { convertCents } from '../../utils/convertCents';
import { formatDate } from '../../utils/formatDate';
import { numberToPercentageFixed } from '../../utils/numberToPercentage';
import Cell from './Cell';
const Column = ({ transaction, userType, wrapperWidth, leftOffset }) => {
    const { recordDate, approvedReceivablesCents, unapprovedReceivablesCents, unselectedReceivablesCents, concentrationAdjustmentCents, availabilityAdjustmentCents, fundingAvailableCents, creditLimitCents, ltv, voidedInvoicesCents, bodTotalOutstandingCents, sodFiuCents, sodOutstandingInterestCents, newDrawdownsCents, drawdownFeesCents, gstCents, dailyInterestCents, repaymentTotalCents, fiuRepaymentCents, residualTransferCents, transferBackCents, eodPrincipalOutstandingCents, eodInterestOutstandingCents, totalOutstandingCents, payments, repaymentTotalCurrency, closingLedgerCents, openingLedgerCents, newInvoicesTotalCents, newInvoicesTotalCurrency, newInvoices, new90DayInvoicesTotalCents, new90DayInvoicesTotalCurrency, new90DayInvoices, oneOffFeeCents, adminFeeCents, insuranceFeeCents, agedReceivablesFeeCents, sodFeesOutstandingCents, eodFeesOutstandingCents, feesChargedCents, interestChargedCents, minimumAdminFeeCents, minimumInterestFeeCents, minimumMonthlyDrawdownFeeCents, sodReserveAmountCents, eodReserveAmountCents, inReserveFromFeeInterestCents, chargeReserveFromFeeCents, chargeReserveFromInterestCents, inReserveFromRepaymentCents, chargeReserveFromDrawdownCents, closingSuspenseCents, unallocatedCreditNotesCents, allocatedCreditNotesCents, totalCreditNotesCents, 
    // manual ledger specific
    grossReceivablesLedgerCents, 
    // hidden until computations are finalized
    // newShadowCreditNotesCents,
    // newAdjustmentsCents,
    // unusualTransactionsCents,
    // amendedTransactionsCents,
    selectedLedgerCents, 
    // newlyAgedDisapprovalsCents,
    // otherDisapprovalsCents,
    unapprovedDebtorsCents, pendingInvoicesCents, ineligibleAdjustmentsCents, approvedLedgerCents, advanceRate, debtorAdvanceRateAdjustmentCents, maximumPermittedFundingCents, sodTotalBalanceOutstandingCents, eodTotalBalanceOutstandingCents
    // facilityLimitExcessCents
     } = transaction;
    const columnRef = useRef(null);
    const [position, setPosition] = useState(0);
    useLayoutEffect(() => {
        if (columnRef.current !== null) {
            setPosition(columnRef.current.offsetLeft);
        }
    }, []);
    const cellAndTooltipWidth = 360 + 144;
    const tooltipClassVariant = cellAndTooltipWidth + position - leftOffset >= wrapperWidth ? '-left' : '';
    // Transaction Values
    const approvedReceivables = convertCents(approvedReceivablesCents);
    const unapprovedReceivables = convertCents(unapprovedReceivablesCents);
    const unselectedReceivables = convertCents(unselectedReceivablesCents);
    const concentrationAdjustment = convertCents(concentrationAdjustmentCents);
    const availabilityAdjustment = convertCents(availabilityAdjustmentCents);
    const fundingAvailable = convertCents(fundingAvailableCents);
    const creditLimit = convertCents(creditLimitCents);
    const voidedInvoices = convertCents(voidedInvoicesCents);
    const bodTotalOutstanding = convertCents(bodTotalOutstandingCents);
    const sodFiu = convertCents(sodFiuCents);
    const sodOutstandingInterest = convertCents(sodOutstandingInterestCents);
    const newDrawdowns = convertCents(newDrawdownsCents);
    const drawdownFees = convertCents(drawdownFeesCents);
    const gst = convertCents(gstCents);
    const dailyInterest = convertCents(dailyInterestCents);
    const totalRepayment = convertCents(repaymentTotalCents);
    const fiuRepayment = convertCents(fiuRepaymentCents);
    const residualTransfer = convertCents(residualTransferCents);
    const transferBack = convertCents(transferBackCents);
    const transfer = transferBack + residualTransfer;
    const eodPrincipalOutstanding = convertCents(eodPrincipalOutstandingCents);
    const eodInterestOutstanding = convertCents(eodInterestOutstandingCents);
    const eodTotalOutstanding = convertCents(totalOutstandingCents);
    const openingLedger = convertCents(openingLedgerCents);
    const newInvoicesTotal = convertCents(newInvoicesTotalCents);
    const closingLedger = convertCents(closingLedgerCents);
    const new90DayInvoicesTotal = convertCents(new90DayInvoicesTotalCents);
    const oneOffFee = convertCents(oneOffFeeCents);
    const adminFees = convertCents(adminFeeCents);
    const insuranceFees = convertCents(insuranceFeeCents);
    const agedReceivableFees = convertCents(agedReceivablesFeeCents);
    const sodFeesOutstanding = convertCents(sodFeesOutstandingCents);
    const eodFeesOutstanding = convertCents(eodFeesOutstandingCents);
    const feesCharged = convertCents(feesChargedCents);
    const interestCharged = convertCents(interestChargedCents);
    const minimumAdminFees = convertCents(minimumAdminFeeCents);
    const minimumInterestFees = convertCents(minimumInterestFeeCents);
    const minimumMonthlyDrawdownFees = convertCents(minimumMonthlyDrawdownFeeCents);
    const sodReserveAmount = convertCents(sodReserveAmountCents);
    const eodReserveAmount = convertCents(eodReserveAmountCents);
    const inReserveFees = convertCents(inReserveFromFeeInterestCents);
    const chargeReserveFees = convertCents(chargeReserveFromFeeCents + chargeReserveFromInterestCents);
    const inRepayments = convertCents(inReserveFromRepaymentCents);
    const chargeDrawdowns = convertCents(chargeReserveFromDrawdownCents);
    const closingSuspense = convertCents(closingSuspenseCents);
    const unallocatedCreditNotes = convertCents(unallocatedCreditNotesCents);
    const allocatedCreditNotes = convertCents(allocatedCreditNotesCents);
    const totalCreditNotes = convertCents(totalCreditNotesCents);
    const date = formatDate(recordDate);
    // manual ledger specific
    const grossReceivablesLedger = convertCents(grossReceivablesLedgerCents);
    // unused for now
    // const newShadowCreditNotes = convertCents(newShadowCreditNotesCents)
    // const newAdjustments = convertCents(newAdjustmentsCents)
    // const unusualTransactions = convertCents(unusualTransactionsCents)
    // const amendedTransactions = convertCents(amendedTransactionsCents)
    const selectedLedger = convertCents(selectedLedgerCents);
    // const newlyAgedDisapprovals = convertCents(newlyAgedDisapprovalsCents)
    // const otherDisapprovals = convertCents(otherDisapprovalsCents)
    const unapprovedDebtors = convertCents(unapprovedDebtorsCents);
    const pendingInvoices = convertCents(pendingInvoicesCents);
    const ineligibleAdjustments = convertCents(ineligibleAdjustmentsCents);
    const approvedLedger = convertCents(approvedLedgerCents);
    const debtorAdvanceRateAdjustment = convertCents(debtorAdvanceRateAdjustmentCents);
    const maximumPermittedFunding = convertCents(maximumPermittedFundingCents);
    const sodTotalBalanceOutstanding = convertCents(sodTotalBalanceOutstandingCents);
    const eodTotalBalanceOutstanding = convertCents(eodTotalBalanceOutstandingCents);
    // const facilityLimitExcess = convertCents(facilityLimitExcessCents)
    const adminCells = [
        { bg: 'header', value: '' },
        { value: openingLedger, format: 'price' },
        {
            value: newInvoicesTotal,
            format: 'price',
            title: `New Invoices for ${date}`,
            invoices: newInvoices,
            currency: newInvoicesTotalCurrency
        },
        { value: voidedInvoices, format: 'price' },
        { value: closingLedger, format: 'price' },
        { value: approvedReceivables, format: 'price' },
        { value: unapprovedReceivables, format: 'price' },
        { value: unselectedReceivables, format: 'price' },
        { value: concentrationAdjustment, format: 'price' },
        { value: availabilityAdjustment, format: 'price' },
        {
            value: new90DayInvoicesTotal,
            format: 'price',
            title: `New 90 Day Invoices for ${date}`,
            invoices: new90DayInvoices,
            currency: new90DayInvoicesTotalCurrency
        },
        { value: +ltv, format: 'percent' },
        { value: creditLimit, format: 'price' },
        { value: closingSuspense, format: 'price' },
        { value: fundingAvailable, format: 'price' },
        { bg: 'header', value: '' },
        { value: bodTotalOutstanding, format: 'price' },
        { value: sodFiu, format: 'price' },
        { value: sodFeesOutstanding, format: 'price' },
        { value: sodOutstandingInterest, format: 'price' },
        { value: sodReserveAmount, format: 'price' },
        { bg: 'header', value: '' },
        {
            value: totalRepayment,
            format: 'price',
            title: `Repayments for ${date}`,
            payments: payments,
            currency: repaymentTotalCurrency
        },
        { value: fiuRepayment, format: 'price' },
        { value: residualTransfer, format: 'price' },
        { value: transferBack, format: 'price' },
        { value: inRepayments, format: 'price' },
        { bg: 'header', value: '' },
        { value: newDrawdowns, format: 'price' },
        { value: gst, format: 'price' },
        { bg: 'header', value: '' },
        { value: drawdownFees, format: 'price' },
        { value: adminFees, format: 'price' },
        { value: insuranceFees, format: 'price' },
        { value: agedReceivableFees, format: 'price' },
        { value: oneOffFee, format: 'price' },
        { value: minimumAdminFees, format: 'price' },
        { value: minimumInterestFees, format: 'price' },
        { value: minimumMonthlyDrawdownFees, format: 'price' },
        { bg: 'header', value: '' },
        { value: totalCreditNotes, format: 'price' },
        { value: unallocatedCreditNotes, format: 'price' },
        { value: allocatedCreditNotes, format: 'price' },
        { bg: 'header', value: '' },
        { value: inRepayments, format: 'price' },
        { value: inReserveFees, format: 'price' },
        { value: chargeDrawdowns, format: 'price' },
        { value: chargeReserveFees, format: 'price' },
        { bg: 'header', value: '' },
        { value: dailyInterest, format: 'price' },
        { bg: 'header', value: '' },
        { value: feesCharged, format: 'price' },
        { value: interestCharged, format: 'price' },
        { value: eodPrincipalOutstanding, format: 'price' },
        { value: eodFeesOutstanding, format: 'price' },
        { value: eodInterestOutstanding, format: 'price' },
        { value: eodTotalOutstanding, format: 'price' },
        { value: eodReserveAmount, format: 'price' }
    ];
    const adminShadowCells = [
        { bg: 'header', value: '' },
        { value: grossReceivablesLedger, format: 'price' },
        { value: unselectedReceivables, format: 'price' },
        {
            value: newInvoicesTotal,
            format: 'price',
            title: `New Invoices for ${date}`,
            invoices: newInvoices,
            currency: newInvoicesTotalCurrency
        },
        // { value: newShadowCreditNotes, format: 'price' },
        // { value: newAdjustments, format: 'price' },
        // { value: unusualTransactions, format: 'price' },
        // { value: amendedTransactions, format: 'price' },
        { value: selectedLedger, format: 'price' },
        // { value: newlyAgedDisapprovals, format: 'price' },
        // { value: otherDisapprovals, format: 'price' },
        { bg: 'header', value: '' },
        { value: closingSuspense, format: 'price' },
        { value: unapprovedDebtors, format: 'price' },
        { value: pendingInvoices, format: 'price' },
        { value: ineligibleAdjustments, format: 'price' },
        { value: approvedLedger, format: 'price' },
        { bg: 'header', value: '' },
        { value: numberToPercentageFixed(+advanceRate, 2), format: 'percent' },
        { value: debtorAdvanceRateAdjustment, format: 'price' },
        { value: maximumPermittedFunding, format: 'price' },
        { bg: 'header', value: '' },
        { value: sodTotalBalanceOutstanding, format: 'price' },
        { value: sodFiu, format: 'price' },
        { value: sodFeesOutstanding, format: 'price' },
        { value: sodOutstandingInterest, format: 'price' },
        { value: sodReserveAmount, format: 'price' },
        { bg: 'header', value: '' },
        {
            value: totalRepayment,
            format: 'price',
            title: `Repayments for ${date}`,
            payments: payments,
            currency: repaymentTotalCurrency
        },
        { value: fiuRepayment, format: 'price' },
        { value: inRepayments, format: 'price' },
        { value: transferBack, format: 'price' },
        { value: residualTransfer, format: 'price' },
        { bg: 'header', value: '' },
        { value: newDrawdowns, format: 'price' },
        { bg: 'header', value: '' },
        { value: drawdownFees, format: 'price' },
        { value: adminFees, format: 'price' },
        { value: insuranceFees, format: 'price' },
        { value: agedReceivableFees, format: 'price' },
        { value: oneOffFee, format: 'price' },
        { value: minimumAdminFees, format: 'price' },
        { value: minimumInterestFees, format: 'price' },
        { value: minimumMonthlyDrawdownFees, format: 'price' },
        { bg: 'header', value: '' },
        { value: dailyInterest, format: 'price' },
        { bg: 'header', value: '' },
        { value: inRepayments, format: 'price' },
        { value: inReserveFees, format: 'price' },
        { value: chargeDrawdowns, format: 'price' },
        { value: chargeReserveFees, format: 'price' },
        { bg: 'header', value: '' },
        { value: feesCharged, format: 'price' },
        { value: interestCharged, format: 'price' },
        { value: eodPrincipalOutstanding, format: 'price' },
        { value: eodFeesOutstanding, format: 'price' },
        { value: eodInterestOutstanding, format: 'price' },
        { value: eodReserveAmount, format: 'price' },
        { value: eodTotalBalanceOutstanding, format: 'price' },
        { value: availabilityAdjustment, format: 'price' },
        // { value: facilityLimitExcess, format: 'price' },
        { value: fundingAvailable, format: 'price' },
        { value: creditLimit, format: 'price' },
        { value: +ltv, format: 'percent' }
    ];
    const borrowerCells = [
        { value: approvedReceivables, format: 'price' },
        { value: fundingAvailable, format: 'price' },
        { bg: 'header', value: '' },
        {
            value: totalRepayment,
            format: 'price',
            title: `Repayments for ${date}`,
            payments: payments,
            currency: repaymentTotalCurrency
        },
        { value: fiuRepayment, format: 'price' },
        { value: residualTransfer, format: 'price' },
        { value: transfer, format: 'price' },
        { bg: 'header', value: '' },
        { value: newDrawdowns, format: 'price' },
        { value: gst, format: 'price' },
        { bg: 'header', value: '' },
        { value: drawdownFees, format: 'price' },
        { value: adminFees, format: 'price' },
        { value: insuranceFees, format: 'price' },
        { value: agedReceivableFees, format: 'price' },
        { value: minimumAdminFees, format: 'price' },
        { value: minimumInterestFees, format: 'price' },
        { value: minimumMonthlyDrawdownFees, format: 'price' },
        { bg: 'header', value: '' },
        { value: dailyInterest, format: 'price' },
        { bg: 'header', value: '' },
        { value: eodPrincipalOutstanding, format: 'price' },
        { value: eodInterestOutstanding, format: 'price' },
        { value: eodTotalOutstanding, format: 'price' }
    ];
    const borrowerShadowCells = [
        { bg: 'header', value: '' },
        { value: grossReceivablesLedger, format: 'price' },
        { value: unselectedReceivables, format: 'price' },
        {
            value: newInvoicesTotal,
            format: 'price',
            title: `New Invoices for ${date}`,
            invoices: newInvoices,
            currency: newInvoicesTotalCurrency
        },
        // hidden until computations are finalized
        // { value: newShadowCreditNotes, format: 'price' },
        // { value: newAdjustments, format: 'price' },
        { value: selectedLedger, format: 'price' },
        // hidden until computations are finalized
        // { value: newlyAgedDisapprovals, format: 'price' },
        // { value: otherDisapprovals, format: 'price' },
        { bg: 'header', value: '' },
        { value: closingSuspense, format: 'price' },
        { value: unapprovedDebtors, format: 'price' },
        { value: pendingInvoices, format: 'price' },
        { value: ineligibleAdjustments, format: 'price' },
        { value: approvedLedger, format: 'price' },
        { bg: 'header', value: '' },
        { value: numberToPercentageFixed(+advanceRate, 2), format: 'percent' },
        { value: debtorAdvanceRateAdjustment, format: 'price' },
        { value: maximumPermittedFunding, format: 'price' },
        { bg: 'header', value: '' },
        { value: sodTotalBalanceOutstanding, format: 'price' },
        { value: sodFiu, format: 'price' },
        { value: sodFeesOutstanding, format: 'price' },
        { value: sodOutstandingInterest, format: 'price' },
        { value: sodReserveAmount, format: 'price' },
        { bg: 'header', value: '' },
        {
            value: totalRepayment,
            format: 'price',
            title: `Repayments for ${date}`,
            payments: payments,
            currency: repaymentTotalCurrency
        },
        { bg: 'header', value: '' },
        { value: newDrawdowns, format: 'price' },
        { bg: 'header', value: '' },
        { value: drawdownFees, format: 'price' },
        { value: adminFees, format: 'price' },
        { value: insuranceFees, format: 'price' },
        { value: agedReceivableFees, format: 'price' },
        { value: oneOffFee, format: 'price' },
        { value: minimumAdminFees, format: 'price' },
        { value: minimumInterestFees, format: 'price' },
        { value: minimumMonthlyDrawdownFees, format: 'price' },
        { bg: 'header', value: '' },
        { value: dailyInterest, format: 'price' },
        { bg: 'header', value: '' },
        { value: feesCharged, format: 'price' },
        { value: interestCharged, format: 'price' },
        { value: eodPrincipalOutstanding, format: 'price' },
        { value: eodFeesOutstanding, format: 'price' },
        { value: eodInterestOutstanding, format: 'price' },
        { value: eodReserveAmount, format: 'price' },
        { value: eodTotalBalanceOutstanding, format: 'price' },
        { value: availabilityAdjustment, format: 'price' },
        // { value: facilityLimitExcess, format: 'price' },
        { value: fundingAvailable, format: 'price' },
        { value: creditLimit, format: 'price' }
    ];
    return (React.createElement("li", { className: 'column transaction-column', "data-testid": 'column', ref: columnRef },
        React.createElement("div", { className: 'heading', key: 'heading' }, date),
        userType === 'admin' &&
            adminCells.map((cell, i) => (React.createElement(Cell, { key: i, ...{ tooltipClassVariant, ...cell } }))),
        userType === 'adminShadow' &&
            adminShadowCells.map((cell, i) => (React.createElement(Cell, { key: i, ...{ tooltipClassVariant, ...cell } }))),
        userType === 'borrower' &&
            borrowerCells.map((cell, i) => (React.createElement(Cell, { key: i, ...{ tooltipClassVariant, ...cell } }))),
        userType === 'borrowerShadow' &&
            borrowerShadowCells.map((cell, i) => (React.createElement(Cell, { key: i, ...{ tooltipClassVariant, ...cell } })))));
};
export default Column;
